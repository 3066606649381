/* Import the font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body {
  font-family: 'Poppins', Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333333;
  margin: 0;
}

/* Hero section styles */
.hero {
  padding: 50px 20px;
  background: linear-gradient(135deg, #fff1eb 0%, #ace0f9 100%);
}

.hero-inner {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.hero-content,
.hero-steps {
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 20px;
}

.hero-content {
  max-width: 600px;
}

.hero-content h2 {
  font-size: 50px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #16a085; /* Updated to use the same color */
}

.hero-content p {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 1.5;
}

.hero-steps {
  max-width: 600px;
}

.steps-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.steps-list li {
  margin-bottom: 30px;
  counter-increment: step-counter;
  position: relative;
  padding-left: 60px;
}

.steps-list li::before {
  content: counter(step-counter);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 40px;
  color: #f39c12;
  font-weight: 700;
}

.hero-steps h3 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 700;
  color: #16a085; /* Same color */
}

.hero-steps p {
  color: #555555;
  font-size: 16px;
  line-height: 1.5;
}

/* Waitlist section styles */
.waitlist {
  background: #ffffff;
  color: #333333;
  padding: 60px 20px;
  text-align: center;
}

.waitlist h2 {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 36px;
  color: #16a085; /* Updated to use the same color */
}

.waitlist p {
  font-size: 18px;
  margin-bottom: 30px;
  color: #555555;
}

.waitlist input[type='email'] {
  padding: 15px;
  width: 350px;
  max-width: 90%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  font-size: 16px;
  border-radius: 5px;
}

.waitlist button {
  padding: 15px 30px;
  border: none;
  background: #f39c12;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  font-weight: 700;
}

.waitlist button:hover {
  background: #d68910;
}

.success-message {
  color: #16a085;
  font-size: 18px;
  margin-top: 20px;
}

.error-message {
  color: #e74c3c;
  font-size: 18px;
  margin-top: 20px;
}

.waitlist button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.waitlist input[type='email']:focus,
.waitlist button:focus {
  outline: 2px solid #16a085;
  outline-offset: 2px;
}

/* Features section styles */
.features {
  padding: 50px 20px;
  text-align: center;
  background-color: #f0f0f0;
}

.features h2 {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 36px;
  color: #16a085; /* Same color */
}

.feature-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.feature {
  background: #ffffff;
  padding: 30px;
  margin: 15px;
  box-sizing: border-box;
  flex: 1 1 calc(30% - 60px);
  max-width: calc(30% - 60px);
  border-radius: 10px;
  border: 1px solid #dddddd;
}

.feature h3 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 700;
  color: #16a085; /* Same color */
}

.feature p {
  color: #555555;
  font-size: 16px;
  line-height: 1.5;
}

/* Trusted by Professionals section styles */
.trusted {
  padding: 50px 20px;
  text-align: center;
  background-color: #ffffff;
}

.trusted h2 {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 36px;
  color: #16a085; /* Same color */
}

.logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.logo-item {
  margin: 20px;
  font-size: 60px;
  color: #555555;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.logo-item:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .hero-inner {
    flex-direction: column;
    align-items: flex-start;
  }

  .hero-content,
  .hero-steps {
    flex: 1 1 100%;
    padding: 10px;
  }

  .hero-content h2 {
    font-size: 36px;
  }

  .hero-content p {
    font-size: 18px;
  }

  .hero-steps h3 {
    font-size: 22px;
  }

  .steps-list li {
    padding-left: 50px;
  }

  .steps-list li::before {
    font-size: 30px;
  }

  .feature,
  .how-it-works-list li {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .logo-item {
    font-size: 40px;
    margin: 10px;
  }
}

/* Footer styles */
.footer {
  background: #2c3e50;
  color: #ffffff;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}

.footer p {
  margin: 0;
  font-size: 14px;
}
